import React, { createContext, useState, useEffect } from 'react';
import { AuthService } from '../services/AuthService'; // Importando o serviço de autenticação

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);  // Armazena os dados do usuário decodificado

  // Função para verificar a sessão e decodificar o id_token
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await AuthService.isAuthenticated();
        
        if (response.isAuthenticated) {
          setIsAuthenticated(true);
          setUser(response);  // Definir os dados do usuário obtidos do check-session
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        console.error('Erro ao verificar autenticação', error);
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = () => {
    AuthService.login();
  };

  const logout = async () => {
    try {
      await AuthService.logout();
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error("Erro no logout", error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
