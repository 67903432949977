import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading, login } = useContext(AuthContext);

  if (loading) {
    return <p>Carregando...</p>; // Mostra um indicador de carregamento enquanto a autenticação está sendo verificada
  }

  if (!isAuthenticated) {
    login();
  }

  // Renderiza a página se o usuário estiver autenticado
  return children;
};

export default ProtectedRoute;
