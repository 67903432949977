import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="hamburger" onClick={toggleSidebar}>
        <FaBars />
      </button>
      <nav className={`sidebar-menu ${isOpen ? 'active' : ''}`}>
        <ul>
          <li><Link to="/user">Usuário</Link></li>
          <li><Link to="/subscribe">Assinatura</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
