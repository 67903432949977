import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import RegisterPage from './pages/RegisterPage';
import UserPage from './pages/UserPage';
import ProtectedRoute from './components/ProtectedRoute'; // Componente que protege a rota
import { AuthProvider } from './context/AuthContext';
import HomePage from './pages/HomePage';
import FailurePage from './pages/payment/FailurePage';
import SuccessPage from './pages/payment/SuccessPage';
import SubscriptionPage from './pages/payment/SubscriptionPage';

function App() {
  return (
    <AuthProvider>
    <Router>
      <div className="App">
        <Header />
        <Sidebar />
        <main className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/payment/success" element={<SuccessPage />} />
              <Route path="/payment/failure" element={<FailurePage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/subscribe" element={<ProtectedRoute> <SubscriptionPage /> </ProtectedRoute>} />
              <Route path="/user" element={<ProtectedRoute> <UserPage /></ProtectedRoute> }
              />
            </Routes>
        </main>
        <Footer />
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
