import React, { useEffect } from 'react';
import { AuthService } from '../services/AuthService';

const LoginPage = () => {

  useEffect(() => {
    AuthService.login();
  },[])

  return (
    <div>
      
    </div>
  );
};

export default LoginPage;
