const SuccessPage = () => {
    return (
      <div>
        <h1>Pagamento realizado com sucesso!</h1>
        <p>Obrigado por assinar o nosso SaaS. Você receberá um e-mail com as instruções.</p>
      </div>
    );
  };
  
  export default SuccessPage;
  