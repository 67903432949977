const FailurePage = () => {
    return (
      <div>
        <h1>Pagamento falhou!</h1>
        <p>Por favor, tente novamente ou entre em contato com o suporte.</p>
      </div>
    );
  };
  
  export default FailurePage;
  