import React, { useEffect } from 'react';
import { AuthService } from '../services/AuthService';

const LogoutPage = () => {

  useEffect(() => {
    AuthService.logout();
  },[])

  return (
    <div>
      
    </div>
  );
};

export default LogoutPage;
