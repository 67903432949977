import React, { useState, useEffect, useContext } from 'react';
import PaymentService from './../../services/PaymentService';
import AuthContext from '../../context/AuthContext';

const SubscriptionPage = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const { user} = useContext(AuthContext);

  useEffect(() => {
    const fetchPlans = async () => {
      const rplans = await PaymentService.getPlans();
      setPlans(rplans);
      setLoading(false);
    };
    fetchPlans();
  }, []);

  const handleSubscription = async (planId, userId) => {
    const session = await PaymentService.createSubscription(planId, userId);
    console.log('URL: ' + session.checkoutUrl)
    window.location.href =  session.checkoutUrl; // Redirecionar para a página de pagamento
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h1>Assine nossos serviços</h1>
      <ul>
        {plans.map((plan) => (
          <li key={plan.id}>
            <h2>{plan.name}</h2>
            <p>{plan.description}</p>
            <button onClick={() => handleSubscription(plan.id, user.id)}>Assinar {plan.price} BRL</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionPage;
