import React, { useState } from 'react';
import { AuthService } from '../services/AuthService';

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await AuthService.register(name, email, password);
      alert('Registrado com sucesso!');
    } catch (error) {
      alert('Erro ao registrar');
    }
  };

  return (
    <div>
      <h1>Registrar</h1>
      <form onSubmit={handleRegister}>
      <div>
          <label>Name:</label>
          <input type="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <label>Senha:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit">Registrar</button>
      </form>
    </div>
  );
};

export default RegisterPage;
