import axios from 'axios';

const authServiceUrl = process.env.REACT_APP_MS_AUTH_URL;

export const AuthService = {

    
  login: () => {
    window.location.href = `${authServiceUrl}/login`;
  },
  
  register: async (name, email, password) => {
    try{
        return await axios.post(`${authServiceUrl}/v1.0/users/register`, {name, email, password});

    }catch(error){
        console.log(error)
        throw error;
    }
  },

  logout: async () => {
    const response = await axios.post(
      `${authServiceUrl}/logout`,
      {},
      { withCredentials: true }
    );
    
    // Redirecionar para o URL de logout obtido na resposta
    if (response.status === 200) {
      window.location.href = response.data.logoutUrl;
    }
  },

  isAuthenticated: async () => {
    try {
      const response = await axios.get(
        `${authServiceUrl}/check-session`,
        { withCredentials: true }
      );
      
      if (response.status === 200) {
        return {
          isAuthenticated: response.data.isAuthenticated,
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          picture: response.data.picture,
        };
      }
      return { isAuthenticated: false };
    } catch (error) {
      return { isAuthenticated: false };
    }
  }
};
