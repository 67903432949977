import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';

const UserPage = () => {
  const { user, isAuthenticated } = useContext(AuthContext);

  if(!isAuthenticated) return null;
  return (
    <div>
      <h1>Dados do Usuário</h1>
      <p><strong>Nome:</strong> {user.name}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <img src={user.picture} alt="User" />
    </div>
  );
};

export default UserPage;
