import axios from 'axios';

const authServiceUrl = process.env.REACT_APP_MS_PAYMENT_URL;

const PaymentService = {
  getPlans: async () => {
    try {
      const response = await axios.get(`${authServiceUrl}/v1.0/plans`, {});
        return response.data;
    } catch (error) {
        console.error("Erro ao obter planos", error);
        throw error;
    }
  },
        
  createSubscription: async (planId, userId) => {
    try {
      const response = await axios.post(`${authServiceUrl}/v1.0/payments/subscriptions`, {
        planId,
        userId,
      });
      return response.data;
    } catch (error) {
      console.error("Erro ao criar assinatura", error);
      throw error;
    }
  },

  getUserTransactions: async (userId) => {
    try {
      const response = await axios.get(`${authServiceUrl}/v1.0/payments/transactions/${userId}`);
      return response.data;
    } catch (error) {
      console.error("Erro ao obter transações do usuário", error);
      throw error;
    }
  },

  getUserSubscriptions: async (userId) => {
    try {
      const response = await axios.get(`${authServiceUrl}/v1.0/payments/subscriptions/${userId}`);
      return response.data;
    } catch (error) {
      console.error("Erro ao obter assinaturas do usuário", error);
      throw error;
    }
  }
};

export default PaymentService;
